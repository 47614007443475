<template>
  <div class="pay">
    <Header>易宝钱包</Header>
    <iframe :src="url" width="100%" height="100%"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: "",
    };
  },
  mounted() {
    if (this.$route.query.url) {
      this.url = this.$route.query.url;
    }
    if (this.$route.query.order_no) {
      const query = {
        token: localStorage.getItem("token"),
        order_no: this.$route.query.order_no,
      };
      this.$api.walletPay(query).then((e) => {
        this.url = e.url;
      });
    }
  },
};
</script>
<style lang="less" scoped>
.pay {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
  }
}
</style>